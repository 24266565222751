








































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import { GET_ALL_JOB_ADS, SAVE_JOB_FAMILIES_TO_JOB_AD } from '@/store/jobAd/actions'
import { CompleteJobOffer } from '@/store/jobAd/types'
import { TableFields } from '@/store/types'
import ClickableIcon from '@/components/common/ClickableIcon.vue'
import { MetaProfile } from '@/store/kode/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { GET_ALL_META_PROFILES } from '@/store/kode/actions'
import InfoBox from '@/components/hszg/InfoBox.vue'
import bootstrapBreakPoints from '@/common/breakpointsBootstrap'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
@Component({
  components: { InfoBox, BaseForm, ClickableIcon, LoadingSpinner }
})
export default class TargetProfiles extends Vue {
  @State('currentScreenWidth') screenWidth: number

  @Action(GET_ALL_JOB_ADS)
  getAllJobAds: () => Promise<Array<CompleteJobOffer>>

  @Action(SAVE_JOB_FAMILIES_TO_JOB_AD)
  saveJobFamiliesToJobAd: (jobAd: CompleteJobOffer) => Promise<Array<CompleteJobOffer>>

  @Action(GET_ALL_META_PROFILES)
  getAllMetaProfiles: () => Promise<Array<MetaProfile>>

  profilesLoading = true
  allProfiles: Array<CompleteJobOffer> = []
  allMetaProfiles: Array<MetaProfile> = []

  maxPerColumn = 1
  currentPage = 1

  private orderBy = 'jobAdDTO.title'

  get fields (): TableFields[] {
    return [
      { key: 'jobAdDTO.title', label: this.$t('target-profiles.job-ad').toString(), sortable: true },
      { key: 'companyDTO.companyName', label: this.$t('target-profiles.company-name').toString(), sortable: true },
      { key: 'jobAdDTO.status', label: this.$t('target-profiles.status').toString(), sortable: true },
      { key: 'relatedJobFamiliesCount', label: this.$t('target-profiles.linked-job-families').toString(), sortable: true },
      { key: 'actions', label: this.$t('actions.action').toString() }
    ]
  }

  get metaProfileFields (): Form[] {
    return [
      {
        id: 'relatedJobFamilies',
        key: 'relatedJobFamilies',
        type: InputTypes.MULTISELECT,
        label: this.$t('target-profiles.profile-name').toString(),
        required: true,
        options: this.allMetaProfiles
      }
    ]
  }

  get perPage (): number {
    return 10
  }

  get totalRows (): number {
    return this.allProfiles.length
  }

  get perColumn (): number {
    if (this.screenWidth < bootstrapBreakPoints.md) return 1
    else if (this.screenWidth < bootstrapBreakPoints.lg) return Math.min(this.maxPerColumn, 2)
    else if (this.screenWidth < bootstrapBreakPoints.xl) return Math.min(this.maxPerColumn, 3)
    else return this.maxPerColumn
  }

  saveJobFamilyToJobAd (item: CompleteJobOffer): void {
    let messageHeadline = ''
    let messageText = ''
    let isError = false
    this.$root.$emit('load')
    this.saveJobFamiliesToJobAd(item).then(() => {
      messageHeadline = this.$t('info.success').toString()
      messageHeadline = this.$t('info.save-success', { what: this.$t('target-profiles.job-ad') }).toString()
      isError = false
    }).catch(error => {
      messageHeadline = this.$i18n.t('warning.error!').toString()
      messageText = error.message
      isError = false
    }).finally(() => {
      this.$root.$emit('alert', messageHeadline, messageText, isError)
      this.$root.$emit('end-load')
    })
  }

  loadData (): void {
    this.profilesLoading = true
    Promise.all([
      this.getAllMetaProfiles().then(data => {
        this.allMetaProfiles = data.map(o => ({
          ...o,
          value: o.id
        }))
      }),
      this.getAllJobAds().then((data) => {
        this.allProfiles = data.map(o => ({
          ...o,
          relatedJobFamilies: o.relatedJobFamilies.map(t => ({ ...t, value: t.id })),
          relatedJobFamiliesCount: o.relatedJobFamilies.length
        }))
      })
    ]).finally(() => {
      this.profilesLoading = false
    })
  }

  created (): void {
    this.loadData()
  }
}
